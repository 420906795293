import React, { useState } from "react";
import { Row, Col, Card, Typography, Input, Select, Button, Spin, Alert, Checkbox, Result } from 'antd';
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { useForm, FormContext } from "react-hook-form";
import states from "../../shared/states.json";
import Form from '../../components/Form/Form';
import FormItem from '../../components/FormItem/FormItem';
import FormItemDouble from '../../components/FormItemDouble/FormItemDouble';
import { withRouter } from "react-router-dom";
import { isEmpty } from 'lodash';
import axios from 'axios';
import { removeEmpty } from "../../shared/objectUtils";

const { Title, Paragraph } = Typography;
const { Option } = Select;

const baseURL = process.env.REACT_APP_IRIS_API_ENDPOINT;

const Shippers = (props) => {

	const methods = useForm({ mode: 'onChange', validateCriteriaMode: 'all' });

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	const onCheckBoxChange = ([event]) => {
		//console.log(event.target.checked);
		return event.target.checked;
	};

	const onSubmit = (data) => {
		data.isShipper = true;
		//console.log(data);

		const cleanedData = removeEmpty(data);
		console.log(cleanedData);

		if (!isEmpty(cleanedData)) {
			setError(null);
			setIsLoading(true);
			axios({
				method: 'post',
				url: `${baseURL}/accountRegistrations`,
				data: cleanedData,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			}).then(res => {
				console.log(res);
				console.log(res.data);
				setIsLoading(false);
				setIsFormSubmitted(true);
				window.scrollTo(0,0);
			}).catch(error => {
				console.log(error);
				setIsLoading(false);
				setError(error.message);
				setIsFormSubmitted(false);
			});
		}
	};

	const formLayout = "vertical";
	const formItemLayout = {
		labelCol: { span: 24 },
		wrapperCol: { span: 24 },
	};
	const formItemLayoutHorizontal = {
		labelCol: { span: 16 },
		wrapperCol: { span: 8 },
	};
	const formItemLayoutDouble = {
		labelCol1: { span: 16 },
		wrapperCol1: { span: 16 },
		labelCol2: { span: 8 },
		wrapperCol2: { span: 8 },
	};

	if (isFormSubmitted === true) {
		return (
			<div id="Confirmation">
				<Row>
					<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
						<SectionHeader size={2} title="SHIPPER APPLICATION" />
					</Col>
				</Row>
				<Row>
					<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
						<Result
							status="success"
							title="Thank you for your application!"
							subTitle="We will be in touch with you soon!"
						/>
					</Col>
				</Row>
			</div>
		);
	} else {
		return (
			<Spin spinning={isLoading === true && error === null}>
				<div>
					<Row>
						<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
							<SectionHeader size={2} title="SHIPPER APPLICATION" />
						</Col>
					</Row>
					<Row>
						<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
							<Card bordered={false}>
								<Paragraph>Thank you for your interest in becoming a Shipper with Pulogix.</Paragraph>
								<Paragraph>Please fill out the form below and a member of our team will be in touch with you regarding next steps!</Paragraph>

								<Title level={4}>Step 1: Fill out the form below.</Title>
								<FormContext {...methods}>
									<Form className="login-form" layout={formLayout} onSubmit={methods.handleSubmit(onSubmit)}>
										<fieldset>
											<legend>Company Contact Information (* indicates a required field)</legend>
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Company Name" required format="vertical"
														as={<Input placeholder="Company Name" />}
														rules={{ required: 'Required Field' }}
														name="name"
													/>
													<FormItem {...formItemLayout} label="Contact Name" required format="vertical"
														as={<Input placeholder="Contact Name" />}
														rules={{ required: 'Required Field' }}
														name="pointOfContact.name"
													/>
													<FormItem {...formItemLayout} label="Email" required format="vertical"
														as={<Input placeholder="Email" />}
														rules={{
															required: 'Required Field',
															minLength: { value: 4, message: 'Email Address must be at least 4 characters long' },
															pattern: {
																value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
																message: "Please enter a valid email address"
															}
														}}
														name="email"
													/>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Federal Id/EIN" required format="vertical"
														as={<Input placeholder="EIN" />}
														rules={{ required: 'Required Field' }}
														name="ein"
													/>
													<FormItemDouble {...formItemLayoutDouble} label1="Day Time Phone #" required1 format="vertical"
														as1={<Input placeholder="Phone #" />}
														rules1={{
															required: 'Required Field',
															pattern: {
																value: /^\d{10}$/,
																message: "Please enter a valid 10 digit phone number with no special characters"
															}
														}}
														name1="phone"
														as2={<Input placeholder="Ext" />}
														rules2={{ required: false }}
														name2="phoneExt"
													/>
													<FormItemDouble {...formItemLayoutDouble} label1="After Hours Phone #" required1 format="vertical"
														as1={<Input placeholder="Phone #" />}
														rules1={{
															required: 'Required Field',
															pattern: {
																value: /^\d{10}$/,
																message: "Please enter a valid 10 digit phone number with no special characters"
															}
														}}
														name1="afterHoursPhone"
														as2={<Input placeholder="Ext" />}
														rules2={{ required: false }}
														name2="afterHoursPhoneExt"
													/>
													<FormItem {...formItemLayout} label="Fax #" format="vertical"
														as={<Input placeholder="Fax #" />}
														rules={{ required: false }}
														name="fax"
													/>
												</Col>
											</Row>
										</fieldset>
										<fieldset>
											<legend>Company Address (* indicates a required field)</legend>
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Street Address 1" required format="vertical"
														as={<Input placeholder="Street Address 1" />}
														rules={{ required: 'Required Field' }}
														name="companyAddress.streetAddress1"
													/>
													<FormItem {...formItemLayout} label="City" required format="vertical"
														as={<Input placeholder="City" />}
														rules={{ required: 'Required Field' }}
														name="companyAddress.city"
													/>
													<FormItem {...formItemLayout} label="Postal Code" required format="vertical"
														as={<Input placeholder="Postal Code" />}
														rules={{ required: 'Required Field' }}
														name="companyAddress.postalCode"
													/>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Street Address 2" format="vertical"
														as={<Input placeholder="Street Address 2" />}
														rules={{ required: false }}
														name="companyAddress.streetAddress2"
													/>
													<FormItem {...formItemLayout} label="State" required format="vertical"
														as={
															<Select
																placeholder={"Please Select a State"}
																allowClear
																showSearch
																optionFilterProp="children"
																filterOption={(input, option) =>
																	option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
																}
																style={{ width: '100%' }}
															>
																{states.map(i => <Option value={i.abbreviation} key={i.abbreviation}>{i.name}</Option>)}
															</Select>
														}
														rules={{ required: 'Required Field' }}
														name="companyAddress.state"
													/>
													<FormItem {...formItemLayout} label="Country" required format="vertical"
														as={
															<Select
																placeholder={"Please Select a Country"}
																allowClear
																showSearch
																optionFilterProp="children"
																filterOption={(input, option) =>
																	option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
																}
																style={{ width: '100%' }}
															>
																<Option value="USA" key="USA">United States</Option>
															</Select>
														}
														rules={{ required: 'Required Field' }}
														name="companyAddress.country"
													/>
												</Col>
											</Row>
										</fieldset>
										<fieldset>
											<legend>What are your equipment needs based on the commodities you ship?</legend>
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<h4 style={{ textAlign: 'center' }}>Reefers</h4>
													<FormItem {...formItemLayoutHorizontal} label="Do you need 48' Reefers?" noColon format="horizontal"
														as={<Checkbox />}
														name="shipper.has48Reefer"
														defaultValue={false}
														valueName="checked"
														onChange={onCheckBoxChange}
													/>
													<FormItem {...formItemLayoutHorizontal} label="Do you need 53' Reefers?" noColon format="horizontal"
														as={<Checkbox />}
														name="shipper.has53Reefer"
														defaultValue={false}
														valueName="checked"
														onChange={onCheckBoxChange}
													/>
													<FormItem {...formItemLayoutHorizontal} label="Do you need to ship frozen?" noColon format="horizontal"
														as={<Checkbox />}
														name="shipper.hasReeferFrozen"
														defaultValue={false}
														valueName="checked"
														onChange={onCheckBoxChange}
													/>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<h4 style={{ textAlign: 'center' }}>Dry Vans</h4>
													<FormItem {...formItemLayoutHorizontal} label="Do you need 48' Dry Vans?" noColon format="horizontal"
														as={<Checkbox />}
														name="shipper.has48DryVan"
														defaultValue={false}
														valueName="checked"
														onChange={onCheckBoxChange}
													/>
													<FormItem {...formItemLayoutHorizontal} label="Do you need 53' Dry Vans?" noColon format="horizontal"
														as={<Checkbox />}
														name="shipper.has53DryVan"
														defaultValue={false}
														valueName="checked"
														onChange={onCheckBoxChange}
													/>
													<FormItem {...formItemLayoutHorizontal} label="Do you need lift gates for the dry vans?" noColon format="horizontal"
														as={<Checkbox />}
														name="shipper.hasDryVanLiftGates"
														defaultValue={false}
														valueName="checked"
														onChange={onCheckBoxChange}
													/>
													<FormItem {...formItemLayoutHorizontal} label="Do you need roller beds for the dry vans?" noColon format="horizontal"
														as={<Checkbox />}
														name="shipper.hasDryVanRollerBed"
														defaultValue={false}
														valueName="checked"
														onChange={onCheckBoxChange}
													/>
													<h4 style={{ textAlign: 'center' }}>Over-Sized Loads</h4>
													<FormItem {...formItemLayoutHorizontal} label="Do you need to ship over dimensional/overweight loads?" noColon format="horizontal"
														as={<Checkbox />}
														name="shipper.hasOverSized"
														defaultValue={false}
														valueName="checked"
														onChange={onCheckBoxChange}
													/>
												</Col>
											</Row>
										</fieldset>
										{error && <Alert message={error} type="error" />}
										<div
											style={{
												right: 0,
												width: '100%',
												borderTop: '1px solid #e9e9e9',
												padding: '10px 16px',
												background: '#fff',
												textAlign: 'right',
											}}
										>
											<Button type="primary" htmlType="submit" disabled={isLoading === true && error === null}>
												Submit
										</Button>
										</div>
									</Form>
								</FormContext>
							</Card>
						</Col>
					</Row>
				</div>
			</Spin>
		);
	}
}

export default withRouter(Shippers);
