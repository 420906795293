import React, { useState, useMemo } from 'react';
import { NavLink, withRouter, Link, useLocation } from 'react-router-dom';
import { Layout, Menu, Button } from 'antd';
import { Route, Switch } from "react-router";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import classes from "./DrawerLayout.module.scss";
import irisLogoSmall from '../../assets/img/full-logo.png';
import IRISFooter from '../IRISFooter/IRISFooter';
import Home from '../../views/Home/Home';
import Carriers from '../../views/Carriers/Carriers';
import Shippers from '../../views/Shippers/Shippers';
import About from '../../views/About/About';
import Contact from '../../views/Contact/Contact';
import Careers from '../../views/Careers/Careers';
import Terms from '../../views/Terms/Terms';
import Privacy from '../../views/Privacy/Privacy';
import Cookies from '../../views/Cookies/Cookies';
import Investors from '../../views/Investors/Investors';

const { Header, Content } = Layout;

const DrawerLayout = props => {

    const location = useLocation();

    const [currentPath, setCurrentPath] = useState(null);

    let routes = (
        <Switch>
            <Route exact path="/carriers" component={Carriers} />
            <Route exact path="/shippers" component={Shippers} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/about" component={About} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/cookies" component={Cookies} />
            <Route exact path="/careers" component={Careers} />
            <Route exact path="/investors" component={Investors} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/" component={Home} />
            <Route path="*" render={() => <h1>Not found</h1>} />
        </Switch>
    );

    useMemo(() => {
        let pathParts = location.pathname.split('/');
        let basePath = pathParts[1];
        setCurrentPath('/' + basePath);
    }, [location]);

    return (
        <Layout style={{ minHeight: '100vh' }} hasSider={false}>
            <ScrollToTop />
            <Header className={classes.header} style={{ position: 'fixed', zIndex: 100, width: '100%', overflow: 'hidden' }}>
                <div className={classes.leftMenu}>
                    <Link to="/">
                        <img src={irisLogoSmall} className={classes.topLogo} alt="PULOGIX" />
                    </Link>
                </div>
                <div className={classes.rightMenu}>
                    <Button type="default" style={{ color: '#000000', marginRight: '15px' }} href="https://app.pulogix.com">Sign In</Button>
                </div>
            </Header>
            <Content className={classes.content}>
                {routes}
            </Content>
            <IRISFooter />
        </Layout>
    );
};

export default withRouter(DrawerLayout);