import React, { Fragment } from "react";
import { Col, Row, Alert } from "antd";
import { Controller, ErrorMessage, useFormContext } from "react-hook-form";

const FormItemDouble = (props) => {
    const methods = useFormContext();

    const label1 = props.label1;
    const labelCol1 = props.labelCol1;
    const wrapperCol1 = props.wrapperCol1;
    const label2 = props.label2;
    const labelCol2 = props.labelCol2;
    const wrapperCol2 = props.wrapperCol2;
    const format = props.format !== undefined && props.format !== null ? props.format : 'vertical';
    const required1 = props.required1 !== undefined && props.required1 !== null ? props.required1 : false;
    const noColon1 = props.noColon1 !== undefined && props.noColon1 !== null ? props.noColon1 : false;
    const required2 = props.required2 !== undefined && props.required2 !== null ? props.required2 : false;
    const noColon2 = props.noColon2 !== undefined && props.noColon2 !== null ? props.noColon2 : false;
    const name1 = props.name1;
    const as1 = props.as1;
    const rules1 = props.rules1;
    const name2 = props.name2;
    const as2 = props.as2;
    const rules2 = props.rules2;
    const defaultValue1 = props.defaultValue1;
    const defaultValue2 = props.defaultValue2;
    const valueName1 = props.valueName1;
    const valueName2 = props.valueName2;
    const onChange1 = props.onChange1;
    const onChange2 = props.onChange2;
    const disabled1 = props.disabled1;
    const disabled2 = props.disabled2;

    const controller1Props = {
        as: as1,
        name: name1
    };

    if (rules1 !== undefined) {
        controller1Props.rules = rules1;
    }

    if (defaultValue1 !== undefined) {
        controller1Props.defaultValue = defaultValue1;
    }

    if (valueName1 !== undefined) {
        controller1Props.valueName = valueName1;
    }

    if (onChange1 !== undefined) {
        controller1Props.onChange = onChange1;
    }

    if (disabled1 !== undefined) {
        controller1Props.disabled = disabled1;
    }

    //console.log(controller1Props);

    const controller2Props = {
        as: as2,
        name: name2
    };

    if (rules2 !== undefined) {
        controller2Props.rules = rules2;
    }

    if (defaultValue2 !== undefined) {
        controller2Props.defaultValue = defaultValue2;
    }

    if (valueName2 !== undefined) {
        controller2Props.valueName = valueName2;
    }

    if (onChange2 !== undefined) {
        controller2Props.onChange = onChange2;
    }

    if (disabled2 !== undefined) {
        controller2Props.disabled = disabled2;
    }

    //console.log(controller2Props);

    const getError = (errors, name) => {
        if (name !== undefined) {
            let obj = errors;

            name = name.split('.');
            let len = name.length;
            for (let i = 0; i < len - 1; i++) {
                if (obj[name[i]] !== undefined) {
                    //console.log(obj[name[i]]);
                    obj = obj[name[i]];
                }
            }

            return obj[name[len - 1]];
        } else {
            return undefined;
        }
    };

    if (format === 'vertical') {
        return (
            <Fragment>
                {(label1 || label2) &&
                    <Row gutter={[8, 8]} style={{ lineHeight: 2.0 }}>
                        {label1 && <Col {...labelCol1}>{required1 ? '* ' : ''}{label1}{noColon1 ? '' : ':'}</Col>}
                        {label2 && <Col {...labelCol2}>{required2 ? '* ' : ''}{label2}{noColon2 ? '' : ':'}</Col>}
                    </Row>
                }
                <Row gutter={[8, 8]}>
                    <Col {...wrapperCol1}>
                        <Controller control={methods.control} {...controller1Props} />
                    </Col>
                    <Col {...wrapperCol2}>
                        <Controller control={methods.control} {...controller2Props} />
                    </Col>
                </Row>
                {(getError(methods.errors, name1) !== undefined || getError(methods.errors, name2) !== undefined) && (
                    <Row gutter={[8, 8]}>
                        <Col {...wrapperCol1}>
                            <ErrorMessage errors={methods.errors} name={name1}>
                                {({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <Alert key={`${name1}-${type}`} message={message} type="error" />
                                    ))
                                }
                            </ErrorMessage>
                        </Col>
                        <Col {...wrapperCol2}>
                            <ErrorMessage errors={methods.errors} name={name2}>
                                {({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <Alert key={`${name2}-${type}`} message={message} type="error" />
                                    ))
                                }
                            </ErrorMessage>
                        </Col>
                    </Row>
                )}
            </Fragment>
        );
    } else if (format === 'horizontal') {
        return (
            <Fragment>
                <Row gutter={[8, 8]}>
                    {label1 && <Col {...labelCol1} style={{ textAlign: 'right' }}>{required1 ? '* ' : ''}{label1}{noColon1 ? '' : ':'}</Col>}
                    <Col {...wrapperCol1}>
                        <Controller control={methods.control} {...controller1Props} />
                    </Col>
                    {label2 && <Col {...labelCol2} style={{ textAlign: 'right' }}>{required2 ? '* ' : ''}{label2}{noColon2 ? '' : ':'}</Col>}
                    <Col {...wrapperCol2}>
                        <Controller control={methods.control} {...controller2Props} />
                    </Col>
                </Row>
                {(getError(methods.errors, name1) !== undefined || getError(methods.errors, name2) !== undefined) && (
                    <Row gutter={[8, 8]}>
                        <Col {...labelCol1}></Col>
                        <Col {...wrapperCol1}>
                            <ErrorMessage errors={methods.errors} name={name1}>
                                {({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <Alert key={`${name1}-${type}`} message={message} type="error" />
                                    ))
                                }
                            </ErrorMessage>
                        </Col>
                        <Col {...labelCol2}></Col>
                        <Col {...wrapperCol2}>
                            <ErrorMessage errors={methods.errors} name={name2}>
                                {({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <Alert key={`${name2}-${type}`} message={message} type="error" />
                                    ))
                                }
                            </ErrorMessage>
                        </Col>
                    </Row>
                )}
            </Fragment>
        );
    } else {
        return null;
    }
};

export default FormItemDouble;