import React, { Fragment } from "react";
import { Typography, Card } from 'antd';

const { Title, Text } = Typography;

const CardTitleSubtitle = (props) => {
    return (
        <Fragment>
            <Card style={{ paddingTop: 50, backgroundImage: 'linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(' + props.image + ')', backgroundSize: 'cover', backgroundPosition: 'center center', height: 260, maxWidth: '100%', minWidth: '100%', overflow: 'hidden', overflowWrap: 'break-word' }} bordered={false}>
                <Typography>
                    <Title level={props.size ? props.size : 3} style={{ color: '#ffffff' }}>{props.title}</Title>
                    <Text strong={true} style={{ color: '#ffffff' }}>{props.subtitle}</Text>
                </Typography>
            </Card>
        </Fragment>
    )
}

export default CardTitleSubtitle;