import React from "react";
import { Row, Col, Typography, Card } from 'antd';
import ContactForm from "../../components/ContactForm/ContactForm";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import CardTitleBody from "../../components/CardTitleBody/CardTitleBody";
import { PhoneOutlined } from '@ant-design/icons';
import DataAddressRow from "../../components/DataAddressRow/DataAddressRow";
import { withRouter } from "react-router-dom";

const { Title, Paragraph } = Typography;

const Contact = (props) => {

	return (
		<div>
			<Row>
				<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
					<SectionHeader size={2} title="CONTACT US" />
				</Col>
			</Row>
			<Row>
				<Col lg={{ span: 7, offset: 4 }} md={{ span: 8, offset: 3 }} xs={{ span: 22, offset: 1 }}>
					<Card bordered={false}>
						<Typography>
							<Title level={3}>Send us a line!</Title>
							<ContactForm />
						</Typography>
					</Card>
				</Col>
				<Col lg={{ span: 7, offset: 2 }} md={{ span: 8, offset: 2 }} xs={{ span: 22, offset: 1 }}>
					<CardTitleBody size={3} title="Let's ship together.">
						<Paragraph>
							Send us a note and let's connect!
              			</Paragraph>
					</CardTitleBody>
					<CardTitleBody size={3} title="Pulogix">
						<DataAddressRow
							street1="4445 North 36th Street"
							street2="Unit 312"
							city="Phoenix"
							state="AZ"
							postalCode="85018"
						/>
						<Paragraph>
							<a className="link" href='tel:4807445700'><PhoneOutlined /> (480) 744-5700</a>
						</Paragraph>
					</CardTitleBody>
				</Col>
			</Row>
		</div>
	);
}

export default withRouter(Contact);
