import React, { Fragment } from "react";
import { Typography, Card } from 'antd';

const { Title, Paragraph } = Typography;

const CardTitleBodyWithImage = (props) => {
    return (
        <Fragment>
            <Card cover={<img alt={props.title} src={props.imageSrc} />} bordered={false}>
                <Typography>
                    <Title level={props.size} className="center-text">
                        {props.title}
                    </Title>
                    <Paragraph>
                        {props.children}
                    </Paragraph>
                </Typography>
            </Card>
        </Fragment>
    )
}

export default CardTitleBodyWithImage;