import React from "react";
import { Layout, Button, Row, Col } from 'antd';
import classes from './IRISFooter.module.scss';
import irisLogoSmall from '../../assets/img/full-logo.png';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import { PhoneOutlined } from '@ant-design/icons';

const { Footer } = Layout;
const version = process.env.REACT_APP_VERSION

const IRISFooter = () => {
    return (
        <Footer style={{ textAlign: 'center', minWidth: '300px' }} className={classes.footer}>
            <section style={{ paddingBottom: 20 }}>
                <Row>
                    <Col span={6} className={classes.footerSection}>

                    </Col>
                    <Col span={12} className={classes.footerSection}>
                        <DataAddressRow
                            street1="5122 N 86th Pl"
                            street2=""
                            city="Scottsdale"
                            state="AZ"
                            postalCode="85250"
                        />
                        <div><a href='tel:480-485-5055'><PhoneOutlined /> (480) 485-5055</a></div>
                        <div><a href={"@mailto:info@pulogix.com"}>info@pulogix.com</a></div>
                    </Col>
                    <Col span={6} className={classes.footerSection}>

                    </Col>
                </Row>
            </section>
            <section style={{ borderTop: '2px solid #e8e8e8', paddingTop: 20 }}>
                <div>
                    <span>Pulogix &copy; {new Date().getFullYear()}. All rights reserved.</span>
                </div>
            </section>
        </Footer>
    );
};

export default IRISFooter;