export const removeEmpty = (obj) => {
    Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === 'object' && Object.keys(obj[key]).length > 0) removeEmpty(obj[key]);
        else if (obj[key] === undefined) delete obj[key];
        else if (obj[key] === "") delete obj[key];

        if (obj[key] && typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) delete obj[key];
    });
    
    return obj;
};

export const convertToObject = (data) => {
    let obj = {};
    let t;
    let parts;
    let part;

    for (let k in data) {
        t = obj;
        parts = k.split('.');
        var key = parts.pop();
        while (parts.length) {
            part = parts.shift();
            t = t[part] = t[part] || {};
        }
        t[key] = data[k]
    }

    return obj;
};