import React, { Fragment } from "react";
import { Col, Row, Alert } from "antd";
import { Controller, ErrorMessage, useFormContext } from "react-hook-form";

const FormItem = (props) => {
    const methods = useFormContext();

    const label = props.label;
    const labelCol = props.labelCol;
    const wrapperCol = props.wrapperCol;
    const format = props.format !== undefined && props.format !== null ? props.format : 'vertical';
    const required = props.required !== undefined && props.required !== null ? props.required : false;
    const noColon = props.noColon !== undefined && props.noColon !== null ? props.noColon : false;
    const as = props.as;
    const rules = props.rules;
    const name = props.name;
    const defaultValue = props.defaultValue;
    const valueName = props.valueName;
    const onChange = props.onChange;
    const disabled = props.disabled;
    const help = props.help;
    const style = props.style;

    const controllerProps = {
        as: as,
        name: name
    };

    if (rules !== undefined) {
        controllerProps.rules = rules;
    }

    if (defaultValue !== undefined) {
        controllerProps.defaultValue = defaultValue;
    }

    if (valueName !== undefined) {
        controllerProps.valueName = valueName;
    }

    if (onChange !== undefined) {
        controllerProps.onChange = onChange;
    }

    if (disabled !== undefined) {
        controllerProps.disabled = disabled;
    }

    //console.log(controllerProps);

    const getError = (errors, name) => {
        if (name !== undefined) {
            let obj = errors;

            name = name.split('.');
            let len = name.length;
            for (let i = 0; i < len - 1; i++) {
                if (obj[name[i]] !== undefined) {
                    //console.log(obj[name[i]]);
                    obj = obj[name[i]];
                }
            }

            return obj[name[len - 1]];
        } else {
            return undefined;
        }
    };

    if (format === 'vertical') {
        return (
            <Fragment>
                {label &&
                    <Row gutter={[8, 8]} style={{ lineHeight: 2.0 }}>
                        <Col span={24}>{required ? '* ' : ''}{label}{noColon ? '' : ':'}</Col>
                    </Row>
                }
                <Row gutter={[8, 8]}>
                    <Col span={24} style={style}>
                        <Controller control={methods.control} {...controllerProps} />
                    </Col>
                </Row>
                {help &&
                    <Row gutter={[8, 8]}>
                        <Col span={24}><h4>{help}</h4></Col>
                    </Row>
                }
                {getError(methods.errors, name) !== undefined && (
                    <ErrorMessage errors={methods.errors} name={name}>
                        {({ messages }) =>
                            messages &&
                            Object.entries(messages).map(([type, message]) => (
                                <Alert key={`${name}-${type}`} message={message} type="error" />
                            ))
                        }
                    </ErrorMessage>
                )}
            </Fragment>
        );
    } else if (format === 'horizontal') {
        return (
            <Fragment>
                <Row gutter={[8, 8]}>
                    {label && <Col {...labelCol} style={{ textAlign: 'right' }}>{required ? '* ' : ''}{label}{noColon ? '' : ':'}</Col>}
                    <Col {...wrapperCol}>
                        <Controller control={methods.control} {...controllerProps} />
                    </Col>
                </Row>
                {help &&
                    <Row gutter={[8, 8]}>
                        <Col {...labelCol}></Col>
                        <Col {...wrapperCol}><h4>{help}</h4></Col>
                    </Row>
                }
                {getError(methods.errors, name) !== undefined && (
                    <Row gutter={[8, 8]}>
                        <Col {...labelCol}></Col>
                        <Col {...wrapperCol}>
                            <ErrorMessage errors={methods.errors} name={name}>
                                {({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <Alert key={`${name}-${type}`} message={message} type="error" />
                                    ))
                                }
                            </ErrorMessage>
                        </Col>
                    </Row>
                )}
            </Fragment>
        );
    } else {
        return null;
    }
};

export default FormItem;