import React, { Fragment } from "react";
import { Typography, Divider } from 'antd';

const { Title } = Typography;

const SectionHeader = (props) => {
    return (
        <Fragment>
            <Typography>
                <Title level={props.size} className="section-header">{props.title}</Title>
            </Typography>
            <Divider className="section-divider" />
        </Fragment>
    )
}

export default SectionHeader;