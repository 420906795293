import React, {Fragment} from 'react';
import {Card, Col, Row, Typography} from "antd";
import classes from './CookiePolicy.module.scss';

const { Title } = Typography;

const CookiePolicy = props => {

    return (
        <Card bordered={true} className={classes.card} size="small">
            <Row gutter={[4, 4]}>
                <Col span={24}>
                    <Title level={2} style={{ textAlign: 'center' }}>Cookie Policy</Title>
                </Col>
            </Row>
            <Row gutter={[4, 4]}>
                <Col sm={{ span: 24 }} xs={{ span: 24 }}>
                    <Fragment>
                        <p style={{ textAlign: 'center' }}><strong>Pulogix TECHNOLOGIES, INC.</strong></p>
                        <p style={{ textAlign: 'center' }}><strong>COOKIES AND OTHER IDENTIFICATION TECHNOLOGIES</strong></p>
                        <p style={{ textAlign: 'center' }}>Effective Date: April 15, 2020</p>
                        <p> Iris Technologies uses cookies and other identification technologies with our website, mobile
                            applications and emails. A &ldquo;cookie&rdquo; is a small piece of data stored on the browser or
                            device of a user.
                        </p>
                        <p> Iris Technologies utilizes cookies and similar technologies to authenticate users and remember user
                            preferences and settings. They also may be used for other business purposes such as:
                        </p>
                        <ul>
                            <li>judging content effectiveness and popularity.</li>
                            <li>studying site usage and trends in usage.</li>
                            <li>delivering advertising and reviewing the effectiveness of advertising campaigns.</li>
                        </ul>
                        <p> We may allow third parties to measure and analyze usage of our website and applications. Third
                            parties may distribute advertisements for our business through the Internet and track the
                            advertisements&rsquo; performance. These third parties may employ cookies and other technologies in
                            doing so, and in identifying the devices used to connect to our website and apps, &nbsp;as well as
                            when other online sites and services are visited.
                        </p>
                        <p> You have the right to choose not to accept cookies. Most web browsers accept them by default. To
                            reject cookies, you need to follow the instructions on your internet browser which normally are in
                            the &ldquo;Help&rdquo; or &ldquo;Preferences&rdquo; menu. Be aware that refusing cookies may affect
                            the availability or functionality of a website you visit or application you are using.
                        </p>
                    </Fragment>
                </Col>
            </Row>
        </Card>
    );
};

export default CookiePolicy;
