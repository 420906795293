import React, { Fragment, useEffect, useRef } from "react";
import { Button, Typography, Row, Col } from 'antd';
import { withRouter } from "react-router-dom";
import shippersImage from "../../assets/img/shippers.jpg";
import carriersImage from "../../assets/img/carriers.jpg";
import traceabilityImage from "../../assets/img/traceability.jpg";
import visibilityImage from "../../assets/img/visibility.jpg";
import card1Image from "../../assets/img/card1.webp";
import card2Image from "../../assets/img/card2.webp";
import card3Image from "../../assets/img/card3.webp";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import CardTitleSubtitle from "../../components/CardTitleSubtitle/CardTitleSubtitle";
import CardTitleBodyWithImage from "../../components/CardTitleBodyWithImage/CardTitleBodyWithImage";
import TitleSubtitle from "../../components/TitleSubtitle/TitleSubtitle";

const { Title } = Typography;

const Home = (props) => {
	let pageHeader = useRef();

	useEffect(() => {
		if (window.innerWidth < 991) {
			const updateScroll = () => {
				let windowScrollTop = window.pageYOffset / 3;
				pageHeader.current.style.transform =
					"translate3d(0," + windowScrollTop + "px,0)";
			};
			window.addEventListener("scroll", updateScroll);

			return function cleanup() {
				window.removeEventListener("scroll", updateScroll);
			};
		}
	});

	return (
		<Fragment>
			<div className="page-header" data-parallax={true} ref={pageHeader}>
				<Row>
					<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} sm={24}>
						<div className="motto">
							<Typography>
								<Title level={1}>Digital Transformation for supply chain logistics and transportation</Title>
								<Title level={3}></Title>
							</Typography>
						</div>
					</Col>
				</Row>
			</div>
		</Fragment>
	);
}

export default withRouter(Home);
