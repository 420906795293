import React from 'react';

const DataAddressRow = props => {
    let name = props.name;
    let street1 = props.street1;
    let street2 = props.street2;
    let city = props.city;
    let state = props.state;
    let postalCode = props.postalCode;
    let country = props.country;
    let address = props.address;

    if (address !== null && address !== undefined) {
        street1 = address.streetAddress1;
        street2 = address.streetAddress2;
        city = address.city;
        state = address.state;
        postalCode = address.postalCode;
        country = address.country;
    }

    return (
        <div style={props.style}>
            {props.title ? (<div style={props.titleStyle ? { fontWeight: 'bold', ...props.titleStyle } : { fontWeight: 'bold' }}>{props.title}: </div>) : null}
            {name ? (name.length > 0 ? (<div style={props.valueStyle}>{name}</div>) : null) : null}
            {street1 ? (<div style={props.valueStyle}>{street1}</div>) : null}
            {street2 ? (street2.length > 0 ? (<div style={props.valueStyle}>{street2}</div>) : null) : null}
            {city || state || postalCode || country ? (<div style={props.valueStyle}>{city}, {state} {postalCode} {country}</div>) : null}
        </div>
    );
};

export default DataAddressRow;