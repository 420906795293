import React from "react";

const Form = (props) => {
    const className = props.className;
    const onSubmit = props.onSubmit;

    return (
        <form className={className} onSubmit={onSubmit}>
            {props.children}
        </form>
    );
};

export default Form;