import React, { useState } from "react";
import { Button, Form, Input, Alert } from 'antd';
import axios from 'axios';
import ReCaptcha from 'react-recaptcha';
import { UserOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { useForm, Controller, ErrorMessage } from 'react-hook-form';

const { TextArea } = Input;

const baseURL = process.env.REACT_APP_IRIS_API_ENDPOINT;

const ContactForm = (props) => {

	const methods = useForm({ mode: 'onChange', validateCriteriaMode: 'all' });

	const [captchaIsValid, setcaptchaIsValid] = useState(false);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	const verifyCaptchaCallback = (response) => {
		console.log(response);

		setcaptchaIsValid(true);
	}

	const onSubmit = (data) => {
		console.log(data);

		if (captchaIsValid === false) {
			setIsFormSubmitted(false);
			return;
		}

		const name = data.name;
		const emailAddress = data.email;
		const phoneNumber = data.phone;
		const message = data.message;

		const emailBody = name + " sent you a message.<br><br>Email Address: " + emailAddress + "<br>Phone Number: " + phoneNumber + "<br>Message: " + message;
		const emailSubject = name + " sent you a message on PulogixFreight.com";
		console.log(emailBody);
		console.log(emailSubject);

		const email = {
			body: emailBody,
			subject: emailSubject
		};

		axios({
			method: 'post',
			url: `${baseURL}/emailLanding`,
			data: email,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		}).then(res => {
			console.log(res);
			console.log(res.data);

			setIsFormSubmitted(true);
		});
	};

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		},
	};

	const tailFormItemLayout = {
		wrapperCol: {
			xs: {
				span: 24,
				offset: 0,
			},
			sm: {
				span: 16,
				offset: 8,
			},
		},
	};

	if (isFormSubmitted === false) {
		return (
			<div id="ContactForm">
				<Form {...formItemLayout} onSubmit={methods.handleSubmit(onSubmit)} className="login-form" hideRequiredMark={false}>
					<Form.Item label="Name" colon={false} required={true}>
						<Controller control={methods.control}
							as={
								<Input
									placeholder="Name"
									prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
								/>
							}
							rules={{ required: 'Required Field' }}
							name="name"
						/>
						<ErrorMessage errors={methods.errors} name="name">
							{({ message, type }) => <Alert key={`name-${type}`} message={message} type="error" />}
						</ErrorMessage>
					</Form.Item>
					<Form.Item label="Email Address" colon={false} required={true}>
						<Controller control={methods.control}
							as={
								<Input
									placeholder="Email Address"
									prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
								/>
							}
							rules={{
								required: 'Required Field',
								minLength: { value: 4, message: 'Email Address must be at least 4 characters long' },
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									message: "Please enter a valid email address"
								}
							}}
							name="email"
						/>
						<ErrorMessage errors={methods.errors} name="email">
							{({ messages }) =>
								messages &&
								Object.entries(messages).map(([type, message]) => (
									<Alert key={`email-${type}`} message={message} type="error" />
								))
							}
						</ErrorMessage>
					</Form.Item>
					<Form.Item label="Phone Number" colon={false} required={true}>
						<Controller control={methods.control}
							as={
								<Input
									placeholder="Phone Number"
									prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
								/>
							}
							rules={{
								required: 'Required Field',
								pattern: {
									value: /^\d{10}$/,
									message: "Please enter a valid 10 digit phone number with no special characters"
								}
							}}
							name="phone"
						/>
						<ErrorMessage errors={methods.errors} name="phone">
							{({ messages }) =>
								messages &&
								Object.entries(messages).map(([type, message]) => (
									<Alert key={`phone-${type}`} message={message} type="error" />
								))
							}
						</ErrorMessage>
					</Form.Item>
					<Form.Item label="Message" colon={false} required={true}>
						<Controller control={methods.control}
							as={
								<TextArea
									rows={4}
									autoSize={{ minRows: 4 }}
									placeholder="Message"
								/>
							}
							rules={{ required: 'Required Field' }}
							name="message"
						/>
						<ErrorMessage errors={methods.errors} name="message">
							{({ message, type }) => <Alert key={`message-${type}`} message={message} type="error" />}
						</ErrorMessage>
					</Form.Item>
					<Form.Item {...tailFormItemLayout}>
						<ReCaptcha
							sitekey="6LfOpcAUAAAAAHOCDbOOzhu5Qgd4L8a4DLfYBOKE"
							verifyCallback={verifyCaptchaCallback}
						/>
					</Form.Item>
					<Form.Item {...tailFormItemLayout}>
						<Button type="primary" htmlType="submit" style={{ width: '100%' }} className="contact-form-button" disabled={captchaIsValid === false}>
							Send
          				</Button>
					</Form.Item>
				</Form>
			</div>
		);
	} else {
		return (
			<div id="EmailConfirmation">
				<Alert message="Thank you for your message!" type="success" showIcon />
			</div>
		);
	}
};

export default ContactForm;