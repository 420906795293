import React, { Fragment } from "react";
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

const TitleSubtitle = (props) => {
    return (
        <Fragment>
            <Typography>
                <Title level={props.size ? props.size : 3} className="center-text section-header">{props.title}</Title>
                <Paragraph className="center-text section-subheader">{props.subtitle}</Paragraph>
            </Typography>
        </Fragment>
    )
}

export default TitleSubtitle;