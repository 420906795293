import React, { Fragment } from "react";
import { Typography, Card } from 'antd';

const { Title } = Typography;

const CardTitleBody = (props) => {
    return (
        <Fragment>
            <Card bordered={false}>
                <Typography>
                    <Title level={props.size}>
                        {props.title}
                    </Title>
                    {props.children}
                </Typography>
            </Card>
        </Fragment>
    )
}

export default CardTitleBody;