import React, { useState } from "react";
import { Row, Col, Card, Typography, Input, Select, Button, Checkbox, InputNumber, Spin, Alert, Radio, Result } from 'antd';
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { useForm, FormContext } from "react-hook-form";
import states from "../../shared/states.json";
import Form from '../../components/Form/Form';
import FormItem from '../../components/FormItem/FormItem';
import FormItemDouble from '../../components/FormItemDouble/FormItemDouble';
import { withRouter } from "react-router-dom";
import { isEmpty } from 'lodash';
import axios from 'axios';
import { removeEmpty } from "../../shared/objectUtils";

const { Title, Paragraph } = Typography;
const { Option } = Select;

const baseURL = process.env.REACT_APP_IRIS_API_ENDPOINT;

const Carriers = (props) => {

	const methods = useForm({ mode: 'onChange', validateCriteriaMode: 'all' });

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	const onRadioButtonChange = ([event]) => {
		//console.log(event.target.value);
		return event.target.value;
	};

	const onCheckBoxChange = ([event]) => {
		//console.log(event.target.checked);
		return event.target.checked;
	};

	const onSubmit = (data) => {
		data.isCarrier = true;
		//console.log(data);

		let cleanedData = removeEmpty(data);
		console.log(cleanedData);

		if (!isEmpty(cleanedData)) {
			setError(null);
			setIsLoading(true);
			axios({
				method: 'post',
				url: `${baseURL}/accountRegistrations`,
				data: cleanedData,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			}).then(res => {
				console.log(res);
				console.log(res.data);
				setIsLoading(false);
				setIsFormSubmitted(true);
				window.scrollTo(0,0);
			}).catch(error => {
				console.log(error);
				setIsLoading(false);
				setError(error.message);
				setIsFormSubmitted(false);
			});
		}
	};

	const formLayout = "vertical";
	const formItemLayout = {
		labelCol: { span: 24 },
		wrapperCol: { span: 24 },
	};
	const formItemLayoutHorizontal = {
		labelCol: { span: 20 },
		wrapperCol: { span: 4 },
	};
	const formItemLayoutDouble = {
		labelCol1: { span: 16 },
		wrapperCol1: { span: 16 },
		labelCol2: { span: 8 },
		wrapperCol2: { span: 8 },
	};
	const formItemLayoutDoubleHorizontal = {
		labelCol1: { span: 12 },
		wrapperCol1: { span: 1 },
		labelCol2: { span: 6 },
		wrapperCol2: { span: 5 },
	};

	if (isFormSubmitted === true) {
		return (
			<div id="Confirmation">
				<Row>
					<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
						<SectionHeader size={2} title="CARRIER APPLICATION" />
					</Col>
				</Row>
				<Row>
					<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
						<Result
							status="success"
							title="Thank you for submitting your application!"
							subTitle="We will be in touch with you soon!"
						/>
					</Col>
				</Row>
			</div>
		);
	} else {
		return (
			<Spin spinning={isLoading === true && error === null}>
				<div>
					<Row>
						<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
							<SectionHeader size={2} title="CARRIER APPLICATION" />
						</Col>
					</Row>
					<Row>
						<Col lg={{ span: 16, offset: 4 }} md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
							<Card bordered={false}>
								<Paragraph>Thank you for your interest in joining the Pulogix Carrier Network. Here’s what you’ll need to get started:</Paragraph>
								<ul>
									<li>Authority: Active DOT / MC Number</li>
									<li>Safety Rating: Satisfactory or None</li>
									<li>Insurance Requirements:
										<ul>
											<li>Auto Liability $1,000,000</li>
											<li>Cargo Liability $100,000</li>
											<li>Commercial General Liability $1,000,000</li>
											<li>Workers’ Comp. / Employer’s Liability – Statutory / $1,000,000</li>
										</ul>
									</li>
								</ul>
								<Paragraph>Please fill out the form below and a member of our team will be in touch with you regarding next steps!</Paragraph>

								<Title level={4}>Step 1: Fill out the form below.</Title>
								<FormContext {...methods}>
									<Form className="login-form" layout={formLayout} onSubmit={methods.handleSubmit(onSubmit)}>
										<fieldset>
											<legend>Company Contact Information (* indicates a required field)</legend>
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Company Name" required format="vertical"
														as={<Input placeholder="Company Name" />}
														rules={{ required: 'Required Field' }}
														name="name"
													/>
													<FormItem {...formItemLayout} label="Contact Name" required format="vertical"
														as={<Input placeholder="Contact Name" />}
														rules={{ required: 'Required Field' }}
														name="pointOfContact.name"
													/>
													<FormItem {...formItemLayout} label="Email" required format="vertical"
														as={<Input placeholder="Email" />}
														rules={{
															required: 'Required Field',
															minLength: { value: 4, message: 'Email Address must be at least 4 characters long' },
															pattern: {
																value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
																message: "Please enter a valid email address"
															}
														}}
														name="email"
													/>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Federal Id/EIN" required format="vertical"
														as={<Input placeholder="EIN" />}
														rules={{ required: 'Required Field' }}
														name="ein"
													/>
													<FormItemDouble {...formItemLayoutDouble} label1="Day Time Phone #" required1 format="vertical"
														as1={<Input placeholder="Phone #" />}
														rules1={{
															required: 'Required Field',
															pattern: {
																value: /^\d{10}$/,
																message: "Please enter a valid 10 digit phone number with no special characters"
															}
														}}
														name1="phone"
														as2={<Input placeholder="Ext" />}
														rules2={{ required: false }}
														name2="phoneExt"
													/>
													<FormItemDouble {...formItemLayoutDouble} label1="After Hours Phone #" required1 format="vertical"
														as1={<Input placeholder="Phone #" />}
														rules1={{
															required: 'Required Field',
															pattern: {
																value: /^\d{10}$/,
																message: "Please enter a valid 10 digit phone number with no special characters"
															}
														}}
														name1="afterHoursPhone"
														as2={<Input placeholder="Ext" />}
														rules2={{ required: false }}
														name2="afterHoursPhoneExt"
													/>
													<FormItem {...formItemLayout} label="Fax #" format="vertical"
														as={<Input placeholder="Fax #" />}
														rules={{ required: false }}
														name="fax"
													/>
												</Col>
											</Row>
										</fieldset>
										<fieldset>
											<legend>Company Address (* indicates a required field)</legend>
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Street Address 1" required format="vertical"
														as={<Input placeholder="Street Address 1" />}
														rules={{ required: 'Required Field' }}
														name="companyAddress.streetAddress1"
													/>
													<FormItem {...formItemLayout} label="City" required format="vertical"
														as={<Input placeholder="City" />}
														rules={{ required: 'Required Field' }}
														name="companyAddress.city"
													/>
													<FormItem {...formItemLayout} label="Postal Code" required format="vertical"
														as={<Input placeholder="Postal Code" />}
														rules={{ required: 'Required Field' }}
														name="companyAddress.postalCode"
													/>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Street Address 2" format="vertical"
														as={<Input placeholder="Street Address 2" />}
														rules={{ required: false }}
														name="companyAddress.streetAddress2"
													/>
													<FormItem {...formItemLayout} label="State" required format="vertical"
														as={
															<Select
																placeholder={"Please Select a State"}
																allowClear
																showSearch
																optionFilterProp="children"
																filterOption={(input, option) =>
																	option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
																}
																style={{ width: '100%' }}
															>
																{states.map(i => <Option value={i.abbreviation} key={i.abbreviation}>{i.name}</Option>)}
															</Select>
														}
														rules={{ required: 'Required Field' }}
														name="companyAddress.state"
													/>
													<FormItem {...formItemLayout} label="Country" required format="vertical"
														as={
															<Select
																placeholder={"Please Select a Country"}
																allowClear
																showSearch
																optionFilterProp="children"
																filterOption={(input, option) =>
																	option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
																}
																style={{ width: '100%' }}
															>
																<Option value="USA" key="USA">United States</Option>
															</Select>
														}
														rules={{ required: 'Required Field' }}
														name="companyAddress.country"
													/>
												</Col>
											</Row>
										</fieldset>
										<fieldset>
											<legend>Carrier Information (* indicates a required field)</legend>
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="US Docket # (MC, FF, MX)" required format="vertical"
														as={<Input placeholder="MC Number" />}
														rules={{ required: 'Required Field' }}
														name="carrier.mcNum"
													/>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="US DOT #" required format="vertical"
														as={<Input placeholder="DOT #" />}
														rules={{ required: 'Required Field' }}
														name="carrier.dotNum"
													/>
												</Col>
											</Row>
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
													<FormItem {...formItemLayout} label="# of Power Units" format="vertical"
														as={<InputNumber min={0} precision={0} step={1} />}
														rules={{ required: false }}
														name="carrier.numPowerUnits"
													/>
												</Col>
												<Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
													<FormItem {...formItemLayout} label="# of Trailers" format="vertical"
														as={<InputNumber min={0} precision={0} step={1} />}
														rules={{ required: false }}
														name="carrier.numTrailers"
													/>
												</Col>
												<Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
													<FormItem {...formItemLayout} label="# of Drivers" format="vertical"
														as={<InputNumber min={0} precision={0} step={1} />}
														rules={{ required: false }}
														name="carrier.numDrivers"
													/>
												</Col>
												<Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
													<FormItem {...formItemLayout} label="# of HazMat Drivers" format="vertical"
														as={<InputNumber min={0} precision={0} step={1} />}
														rules={{ required: false }}
														name="carrier.numHazMatDrivers"
													/>
												</Col>
												<Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
													<FormItem {...formItemLayout} label="# of Teams" format="vertical"
														as={<InputNumber min={0} precision={0} step={1} />}
														rules={{ required: false }}
														name="carrier.numTeams"
													/>
												</Col>
											</Row>
										</fieldset>
										<fieldset>
											<legend>Accounts Receivable (* indicates a required field)</legend>
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Preferred Payment Method" format="vertical" required
														as={<Radio.Group buttonStyle="solid">
															<Radio.Button value="DIRECT_DEPOSIT">Direct Deposit</Radio.Button>
															<Radio.Button value="QUICK_PAY">Quick Pay</Radio.Button>
															<Radio.Button value="FACTORING">Factoring</Radio.Button>
														</Radio.Group>}
														rules={{ required: 'Required Field' }}
														name="carrier.preferredPaymentMethod"
														onChange={onRadioButtonChange}
													/>
												</Col>
											</Row>
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col span={24}>
													<Paragraph>If your accounts receivable department is different than your physical address, or if you are using a factoring company, please complete below:</Paragraph>
												</Col>
											</Row>
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Person or Company Name" format="vertical"
														as={<Input placeholder="Person or Company Name" />}
														rules={{ required: false }}
														name="carrier.accountsReceivable.name"
													/>
													<FormItem {...formItemLayout} label="Email" format="vertical"
														as={<Input placeholder="Email" />}
														rules={{
															required: false,
															pattern: {
																value: /^undefined|^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
																message: "Please enter a valid email address"
															}
														}}
														name="carrier.accountsReceivable.email"
														defaultValue={''}
													/>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="vertical"
														as1={<Input placeholder="Phone #" />}
														rules1={{
															required: false,
															pattern: {
																value: /^\d{10}$/,
																message: "Please enter a valid 10 digit phone number with no special characters"
															}
														}}
														name1="carrier.accountsReceivable.phone"
														defaultValue1={''}
														as2={<Input placeholder="Ext" />}
														rules2={{ required: false }}
														name2="carrier.accountsReceivable.phoneExt"
													/>
												</Col>
											</Row>
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Street Address 1" format="vertical"
														as={<Input placeholder="Street Address 1" />}
														rules={{ required: false }}
														name="carrier.shippingAddress.streetAddress1"
													/>
													<FormItem {...formItemLayout} label="City" format="vertical"
														as={<Input placeholder="City" />}
														rules={{ required: false }}
														name="carrier.shippingAddress.city"
													/>
													<FormItem {...formItemLayout} label="Postal Code" format="vertical"
														as={<Input placeholder="Postal Code" />}
														rules={{ required: false }}
														name="carrier.shippingAddress.postalCode"
													/>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<FormItem {...formItemLayout} label="Street Address 2" format="vertical"
														as={<Input placeholder="Street Address 2" />}
														rules={{ required: false }}
														name="carrier.shippingAddress.streetAddress2"
													/>
													<FormItem {...formItemLayout} label="State" format="vertical"
														as={
															<Select
																placeholder={"Please Select a State"}
																allowClear
																showSearch
																optionFilterProp="children"
																filterOption={(input, option) =>
																	option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
																}
																style={{ width: '100%' }}
															>
																{states.map(i => <Option value={i.abbreviation} key={i.abbreviation}>{i.name}</Option>)}
															</Select>
														}
														rules={{ required: false }}
														name="carrier.shippingAddress.state"
													/>
													<FormItem {...formItemLayout} label="Country" format="vertical"
														as={
															<Select
																placeholder={"Please Select a Country"}
																allowClear
																showSearch
																optionFilterProp="children"
																filterOption={(input, option) =>
																	option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
																}
																style={{ width: '100%' }}
															>
																<Option value="USA" key="USA">United States</Option>
															</Select>
														}
														rules={{ required: false }}
														name="carrier.shippingAddress.country"
													/>
												</Col>
											</Row>
										</fieldset>
										<fieldset>
											<legend>Trailer Types</legend>
											<Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<h4 style={{ textAlign: 'center' }}>Reefers</h4>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Reefers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														as1={<Checkbox />}
														name1="carrier.has48Reefer"
														defaultValue1={false}
														valueName1="checked"
														onChange1={onCheckBoxChange}
														as2={<InputNumber min={0} precision={0} step={1} />}
														name2="carrier.num48Reefer"
													/>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Reefers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														as1={<Checkbox />}
														name1="carrier.has53Reefer"
														defaultValue1={false}
														valueName1="checked"
														onChange1={onCheckBoxChange}
														as2={<InputNumber min={0} precision={0} step={1} />}
														name2="carrier.num53Reefer"
													/>
													<FormItem {...formItemLayoutHorizontal} label="Do you haul frozen?" noColon format="horizontal"
														as={<Checkbox />}
														name="carrier.hasReeferFrozen"
														defaultValue={false}
														valueName="checked"
														onChange={onCheckBoxChange}
													/>
												</Col>
												<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
													<h4 style={{ textAlign: 'center' }}>Dry Vans</h4>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Dry Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														as1={<Checkbox />}
														name1="carrier.has48DryVan"
														defaultValue1={false}
														valueName1="checked"
														onChange1={onCheckBoxChange}
														as2={<InputNumber min={0} precision={0} step={1} />}
														name2="carrier.num48DryVan"
													/>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Dry Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														as1={<Checkbox />}
														name1="carrier.has53DryVan"
														defaultValue1={false}
														valueName1="checked"
														onChange1={onCheckBoxChange}
														as2={<InputNumber min={0} precision={0} step={1} />}
														name2="carrier.num53DryVan"
													/>
													<FormItem {...formItemLayoutHorizontal} label="Do you have lift gates for the dry vans?" noColon format="horizontal"
														as={<Checkbox />}
														name="carrier.hasDryVanLiftGates"
														defaultValue={false}
														valueName="checked"
														onChange={onCheckBoxChange}
													/>
													<FormItem {...formItemLayoutHorizontal} label="Do you have roller beds for the dry vans?" noColon format="horizontal"
														as={<Checkbox />}
														name="carrier.hasDryVanRollerBed"
														defaultValue={false}
														valueName="checked"
														onChange={onCheckBoxChange}
													/>
													<h4 style={{ textAlign: 'center' }}>Over-Sized Loads</h4>
													<FormItem {...formItemLayoutHorizontal} label="Do you haul over dimensional/overweight loads?" noColon format="horizontal"
														as={<Checkbox />}
														name="carrier.hasOverSized"
														defaultValue={false}
														valueName="checked"
														onChange={onCheckBoxChange}
													/>
												</Col>
											</Row>
											{/* <h4>Flatbeds</h4>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Flatbeds?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														as1={<Checkbox />}
														name1="carrier.has48Flatbed"
														defaultValue1={false}
														valueName1="checked"
														onChange1={onCheckBoxChange}
														as2={<InputNumber min={0} precision={0} step={1} />}
														name2="carrier.num48Flatbed"
													/>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Flatbeds?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														as1={<Checkbox />}
														name1="carrier.has53Flatbed"
														defaultValue1={false}
														valueName1="checked"
														onChange1={onCheckBoxChange}
														as2={<InputNumber min={0} precision={0} step={1} />}
														name2="carrier.num53Flatbed"
													/>
													<h4>Low Boys</h4>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Low Boys?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														as1={<Checkbox />}
														name1="carrier.hasLowBoy"
														defaultValue1={false}
														valueName1="checked"
														onChange1={onCheckBoxChange}
														as2={<InputNumber min={0} precision={0} step={1} />}
														name2="carrier.numLowBoy"
													/>
													<h4>RGNs</h4>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have RGNs?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														as1={<Checkbox />}
														name1="carrier.hasRGN"
														defaultValue1={false}
														valueName1="checked"
														onChange1={onCheckBoxChange}
														as2={<InputNumber min={0} precision={0} step={1} />}
														name2="carrier.numRGN"
													/>
													<h4>Step Decks</h4>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Step Decks with Ramps?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														as1={<Checkbox />}
														name1="carrier.hasStepDeckRamps"
														defaultValue1={false}
														valueName1="checked"
														onChange1={onCheckBoxChange}
														as2={<InputNumber min={0} precision={0} step={1} />}
														name2="carrier.numStepDeckRamps"
													/>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Step Decks without Ramps?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														as1={<Checkbox />}
														name1="carrier.hasStepDeckNoRamps"
														defaultValue1={false}
														valueName1="checked"
														onChange1={onCheckBoxChange}
														as2={<InputNumber min={0} precision={0} step={1} />}
														name2="carrier.numStepDeckNoRamps"
													/>
													<h4>Tankers</h4>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Single Compartment Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														as1={<Checkbox />}
														name1="carrier.hasTankersSingleCompartment"
														defaultValue1={false}
														valueName1="checked"
														onChange1={onCheckBoxChange}
														as2={<InputNumber min={0} precision={0} step={1} />}
														name2="carrier.numTankersSingleCompartment"
													/>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Multi Compartment Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														as1={<Checkbox />}
														name1="carrier.hasTankersMultiCompartment"
														defaultValue1={false}
														valueName1="checked"
														onChange1={onCheckBoxChange}
														as2={<InputNumber min={0} precision={0} step={1} />}
														name2="carrier.numTankersMultiCompartment"
													/>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Fiber Glass Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														as1={<Checkbox />}
														name1="carrier.hasTankersFiberGlass"
														defaultValue1={false}
														valueName1="checked"
														onChange1={onCheckBoxChange}
														as2={<InputNumber min={0} precision={0} step={1} />}
														name2="carrier.numTankersFiberGlass"
													/>
													<h4>Straight Trucks</h4>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Cubed Straight Trucks?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														as1={<Checkbox />}
														name1="carrier.hasCubedStraightTruck"
														defaultValue1={false}
														valueName1="checked"
														onChange1={onCheckBoxChange}
														as2={<InputNumber min={0} precision={0} step={1} />}
														name2="carrier.numCubedStraightTruck"
													/>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 26' Straight Trucks?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														as1={<Checkbox />}
														name1="carrier.has26StraightTruck"
														defaultValue1={false}
														valueName1="checked"
														onChange1={onCheckBoxChange}
														as2={<InputNumber min={0} precision={0} step={1} />}
														name2="carrier.num26StraightTruck"
													/>
													<FormItem {...formItemLayoutHorizontal} label="Do you have lift gates for the straight trucks?" noColon format="horizontal"
														as={<Checkbox />}
														name="carrier.hasStraightTruckLiftGates"
														defaultValue={false}
														valueName="checked"
														onChange={onCheckBoxChange}
													/>
													<h4>Sprinter Vans</h4>
													<FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Sprinter Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
														as1={<Checkbox />}
														name1="carrier.hasSprinterVan"
														defaultValue1={false}
														valueName1="checked"
														onChange1={onCheckBoxChange}
														as2={<InputNumber min={0} precision={0} step={1} />}
														name2="carrier.numSprinterVan"
													/> */}
										</fieldset>
										{error && <Alert message={error} type="error" />}
										<div
											style={{
												right: 0,
												width: '100%',
												borderTop: '1px solid #e9e9e9',
												padding: '10px 16px',
												background: '#fff',
												textAlign: 'right',
											}}
										>
											<Button type="primary" htmlType="submit" disabled={isLoading === true && error === null}>
												Submit
										</Button>
										</div>
									</Form>
								</FormContext>
							</Card>
						</Col>
					</Row>
				</div>
			</Spin>
		);
	}
}

export default withRouter(Carriers);
